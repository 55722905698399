<template>
    <Card dis-hover>
        <div class="form_item">
            <div class="form_label must_input align_self_start">展示图</div>
            <div>
                <uploadImage :width="100" :height="100" v-model="formData.icon"></uploadImage>
                <div class="tip_txt">推荐尺寸： 683 x 271，高度可适当加减，宽度最好保持不变</div>
            </div>
        </div>

        <div class="form_item">
            <div class="form_label must_input align_self_start">类型</div>
            <RadioGroup v-model="formData.mapping" type="button" button-style="solid" @on-change="onChangeMapping">
                <Radio label="1">分类</Radio>
                <Radio label="2">商品</Radio>
            </RadioGroup>
        </div>

        <div v-if="formData.mapping == 1" class="form_item">
            <div class="form_label must_input align_self_start">选择分类</div>
            <Select :key="'goods_class_select_' + key" multiple class="width_500" v-model="formData.mapping_value">
                <Option v-for="info in goodsClassList" :key="info.id" :value="info.id">{{ info.title }}</Option>
            </Select>
        </div>

        <div v-if="formData.mapping == 2" class="form_item">
            <div class="form_label must_input align_self_start">选择商品</div>
            <Select :key="'goods_select_' + key" class="width_500" v-model="formData.mapping_value" filterable :remote-method="getGoodsList" :loading="ajaxLoading" clearable @on-clear="onClearGoods">
                <Option v-for="info in goodsList" :value="info.id" :key="info.id">{{ info.title }}</Option>
            </Select>
        </div>

        <div class="form_item">
            <div class="form_label align_self_start">排序</div>
            <Input class="width_300" type="text" v-model.trim="formData.sorts"></Input>
            <div class="tip_txt">（值越小，在小程序端显示越靠前）</div>
        </div>

        <div class="form_item">
            <div class="form_label align_self_start">是否显示</div>
            <RadioGroup v-model="formData.status" type="button" button-style="solid">
                <Radio label="1">是</Radio>
                <Radio label="0">否</Radio>
            </RadioGroup>
        </div>

        <div class="mt_50 flex align_center justify_center form_item">
            <Button type="primary" ghost @click="onCloseEdit">取消</Button>
            <Button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirmEdit">保存</Button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </Card>
</template>
<script>
import uploadImage from '@/components/uploadImage';
import { reqGoodsListClass, reqSaveMapping, reqGoodsList } from '@/lib/request/auth2';
import { createId, isFunction } from '@/lib/util';
export default {
    name: 'indexTypeAdd',
    components: {
        uploadImage,
    },
    data() {
        return {
            title: '配置信息',
            formData: {
                id: null,
                icon: null,
                mapping: '1', //映射类型 1 分类 2 商品
                mapping_value: null,
                sorts: null,
                status: '1',
            },
            isEdit: false,
            goodsClassList: [],
            goodsList: [],
            baseGoodsList: [],
            checkedGoods: '',
            key: createId(),
        };
    },
    mounted() {
        this.routeParamsInjectFormData();
        this.title = this.isEdit ? '编辑' : '添加';
        this.getGoodsClassList();
        let gid = null;
        if (this.isEdit) {
            if (this.formData.mapping == 2) {
                gid = this.formData.mapping_value;
            } else {
                this.formData.mapping_value = this.formData.mapping_value.split(',');
            }
        }
        this.getGoodsList('', gid, () => {
            this.baseGoodsList = JSON.parse(JSON.stringify(this.goodsList));
        });
    },
    watch: {
        //
    },
    methods: {
        onChangeMapping(evt) {
            this.formData.mapping_value = null;
            this.key = createId();
            if (evt == 2) {
                this.goodsList = JSON.parse(JSON.stringify(this.baseGoodsList));
            }
        },
        //商品分类数据
        getGoodsClassList() {
            this.showAjaxLoading();
            reqGoodsListClass({
                pageNums: 1,
                pageSize: 100,
            })
                .then(res => {
                    let list = (res.data || {}).list || [];
                    let goodsClassList = [];
                    list.forEach(info => {
                        if ((info.children || []).length > 0) {
                            info.children.forEach(subInfo => {
                                goodsClassList.push(subInfo);
                            });
                        }
                    });
                    console.log('getGoodsClassList', goodsClassList);
                    this.goodsClassList = goodsClassList;
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        getGoodsList(query, id, cb = null) {
            this.showAjaxLoading();
            reqGoodsList({
                title: query || '',
                pageNums: 1,
                pageSize: 20,
                id,
            })
                .then(res => {
                    this.goodsList = (res.data || {}).list || [];
                    isFunction(cb) && cb();
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        onClearGoods() {
            this.goodsList = JSON.parse(JSON.stringify(this.baseGoodsList));
        },
        //取消
        onCloseEdit() {
            this.$router.back();
        },
        //确认
        onConfirmEdit() {
            this.showAjaxLoading();
            let params = window.structuredClone(this.formData);
            let { icon, mapping, mapping_value } = params || {};
            let vs = [{ value: icon, tip: '请选择图片' }];
            let isMappingType = mapping == 1;

            // if (1 == 1) {
            //     console.log(JSON.stringify(this.formData, null, '\t'));
            //     this.hideAjaxLoading();
            //     return;
            // }

            hm.validateForm(vs)
                .then(() => {
                    if (isMappingType && (mapping_value || []).length <= 0) {
                        this.hideAjaxLoading();
                        this.fadeWarning('请选择分类');
                        return;
                    } else if (!!!mapping_value) {
                        this.hideAjaxLoading();
                        this.fadeWarning('请选择商品');
                        return;
                    }

                    if (isMappingType) {
                        params.mapping_value = mapping_value.join(',');
                    }

                    reqSaveMapping(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.onCloseEdit();
                        })
                        .finally(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
    },
};
</script>
<style lang="less" scoped></style>
